<template>
  <!-- begin:: Header Topbar -->
  <div class="kt-header__topbar">
    <!--begin: User Bar -->
    <div class="kt-header__topbar-item kt-header__topbar-item--user">
      <div class="kt-header__topbar-wrapper" id="kt_user_toggle">
        <div class="store-name">{{ currentUser.name }}</div>
        <!-- <div class="kt-header__topbar-wrapper" id="kt_user_toggle" data-toggle="dropdown"> -->
        <!-- <div class="kt-header__topbar-user">
          <span class="kt-header__topbar-welcome kt-hidden-mobile">Hi,</span>
          <span class="kt-header__topbar-username kt-hidden-mobile">{{
            currentUser.email
          }}</span>
          <img
            class="kt-hidden"
            alt="Pic"
            src="@/assets/media/users/default.jpg"
        />-->
        <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
        <!-- <span
            class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold"
            >{{
              currentUser.email !== undefined
                ? currentUser.email.substring(0, 1).toUpperCase()
                : ''
            }}</span
          >
        </div>-->
      </div>
      <!-- <div class="dropdown-menu dropdown-menu-fit dropdown-menu-xl dropdown-menu-right">
        <KTDropdownUser></KTDropdownUser>
      </div>-->
    </div>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<script>
// import KTDropdownUser from '@/views/theme/topbar/DropdownUser.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'KTTopbar',
  data() {
    return {};
  },
  components: {
    // KTDropdownUser
  },
  methods: {},
  computed: {
    ...mapGetters(['currentUser'])
  }
};
</script>
<style scoped>
.store-name {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  color: #c6cfda;
}
.kt-header__topbar {
  padding-right: 25px;
}
</style>
